// Core

@import "core";

// Fonts

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";

@import "scrollbars";

body {
  padding: 0,
}

div[role="tooltip"] {
  z-index: 1400;
}

.dnd-portal {
  position: absolute;
  pointer-events: none;
  top: 0;
  width: 100%;
  height: 100%;
}

